@import '~styles/mixins';

.buttonConnect {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  min-width: 260px;
  padding: 0;
  background: $gray-900;
  border: 2px solid $gray-800;

  &:hover,
  &:focus {
    background: $gray-900;
  }
}

.blockIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 9px;
  border-color: $gray-800;
  border-style: solid;
  border-width: 0;
  border-right-width: 2px;

  svg {
    fill: url('#user-gradient');
  }
}
