@import '~styles/mixins';

.cardStakeBlock {
  z-index: 1;
  display: grid;
}

.cardHeading {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  column-gap: 16px;
  align-items: center;
  justify-items: start;
}

.btnPresent {
  justify-self: end;
  color: $gray-300;
  transition: 0.25s color;

  &:hover {
    color: $white;
  }
}

.hrHeading {
  margin: 24px;

  @include b-d(lg) {
    margin: 16px 0;
  }
}

.inputWrapper {
  position: relative;
}

.textBalance {
  position: absolute;
  right: 0;
  color: $gray-500;
}

.gridPercentButtons {
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  margin-top: 8px;
}

.textResult {
  margin-top: 24px;
}

.inputRange {
  margin-top: 8px;
}

.btnStake {
  margin-top: 24px;
}

.table {
  margin-top: 16px;

  td {
    &:first-child {
      color: $gray-500;
    }

    &:last-child {
      font-weight: $font-weight-semi-bold;
      text-align: end;

      > span {
        color: transparent;
        background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
        background-clip: text;
      }
    }
  }

  tr:not(:last-child) td {
    padding-bottom: 16px;
  }
}
