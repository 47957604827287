@import '~styles/mixins';

.blockassetLogo {
  display: grid;
  grid-template-columns: 48px 144px;
  column-gap: 8px;
  align-items: center;

  &.header {
    @include b-d(lg) {
      grid-template-columns: 42px 126px;
    }

    @include b-btw(lg, xxl) {
      grid-template-columns: 48px;

      .textBlockasset {
        display: none;
      }
    }
  }

  &.footer {
    grid-template-columns: 36px 108px;
    column-gap: 6px;
  }
}
