@import '~styles/mixins';

.blockNftStaking {
  display: grid;
  row-gap: 24px;

  @include b-d(md) {
    row-gap: 16px;
  }
}
