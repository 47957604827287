@import '~styles/mixins';

.banner {
  position: relative;
  width: 100%;
  padding-bottom: 25px;
}

.bannerInner {
  display: grid;
  row-gap: 8px;
  align-content: center;
  justify-content: start;
  width: 100%;
  height: 137px;
  padding-left: 40px;
  overflow: hidden;
  font-weight: 300;
  color: $gray-600;
  text-align: start;
  filter: drop-shadow(0 2px $gray-800);
  border: 2px solid $gray-800;
  border-bottom: 0;
  border-radius: 23px;
  transform: translateZ(0);

  @include b-d(lg) {
    align-content: start;
    height: 188px;
    padding-top: 60px;
    padding-left: 24px;
  }
}

.header {
  font-size: 28px;
  line-height: 38px;
  color: transparent;
  letter-spacing: -0.01em;
  background: linear-gradient(
    104.51deg,
    #333 -5.26%,
    #fff 49.16%,
    #2b2b2b 106.37%
  );
  background-clip: text;

  @include b-d(lg) {
    font-size: 18px;
    line-height: 24px;
  }

  @include b-d(md) {
    font-size: 16px;
  }
}

.bg {
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #121212;
  background-image: url('./img/bg.png');
  background-position: center;
  mask-image: url("data:image/svg+xml,%3Csvg width='1453' height='135' viewBox='0 0 1453 135' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1197 95C1179.43 95 1164.57 106.65 1159.69 123.16C1157.82 129.516 1152.63 135 1146 135H0.5C0.505288 90 0.505288 45 0.5 0H1452V135H1388C1381.37 135 1376.19 129.516 1374.31 123.16C1369.43 106.65 1354.58 95 1337 95H1197Z' fill='black'/%3E%3C/svg%3E%0A");
  mask-position: right -5px bottom 0;
  mask-repeat: no-repeat;
  transform: translateZ(0);

  @include b-d(lg) {
    mask-position: center bottom;
  }

  @include b-btw(md, lg) {
    mask-image: url("data:image/svg+xml,%3Csvg width='1100' height='191' viewBox='0 0 1100 191' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1100 0H0V191H353C357.418 191 360.908 187.373 361.878 183.062C365.493 166.999 379.845 155 397 155H705C722.155 155 736.507 166.999 740.122 183.062C741.092 187.373 744.582 191 749 191H1100V0Z' fill='black'/%3E%3C/svg%3E%0A");
  }

  @include b-d(md) {
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' fill='none' viewBox='0 0 600 188'%3E%3Cpath fill='%23000' d='M130 0H0v188h162.5c3.5 0 6.6-3 7.4-6.8a37.5 37.5 0 0 1 36.6-29.2h188a37.5 37.5 0 0 1 36.6 29.2c.8 3.9 3.9 6.8 7.4 6.8H600V0H130Z'/%3E%3C/svg%3E");
    background-size: 180% auto;
  }

  &::after {
    position: absolute;
    z-index: -1;
    width: 417px;
    height: 169px;
    content: '';
    background-image: url('./img/media-banner.png');
    background-repeat: no-repeat;
    background-size: contain;

    @include b-up(lg) {
      top: 10px;
      right: -20px;
    }

    @include b-btw(md, lg) {
      right: -80px;
      bottom: -50px;
    }

    @include b-d(md) {
      right: -70px;
      bottom: -24px;
      width: 254px;
      height: 102px;
    }
  }
}

.button {
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include b-up(lg) {
    right: 85.5px;
    max-width: 194px;
  }

  @include b-d(lg) {
    left: 50%;
    max-width: 356px;
    padding: 14px 32px;
    transform: translateX(-50%);
  }

  @include b-d(md) {
    max-width: 240px;
  }
}
