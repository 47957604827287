.accordion {
  $this: &;

  &-mobile-nav {
    display: grid;
    row-gap: 4px;
    margin: -6px;

    #{$this}-button {
      @include font-family-syne;
      @include text-syne-18-bold;

      &:hover {
        color: $gray-300;
      }
    }
  }
}
