@import '~styles/mixins';

.dropdownToggle {
  background-color: $gray-900;
  border: 2px solid $gray-800;
  border-radius: 100px;

  &[aria-expanded='true'] {
    .overlay {
      visibility: visible;
    }
  }
}

.dropdownMenu {
  hr {
    width: 100%;
    height: 2px;
    margin-bottom: 6px;
    background: linear-gradient(
      270deg,
      rgb(28 28 28 / 0%) 0%,
      #1c1c1c 51.04%,
      rgb(28 28 28 / 0%) 100%
    );
  }
}

.blockBalance {
  padding: 6px 12px 6px 14px;
  font-weight: $font-weight-base;
}

.toggleInner {
  position: relative;
  margin-right: -2px;
  margin-left: -2px;
  transform: translate(0);
}

/* stylelint-disable-next-line no-descending-specificity */
.overlay {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin: 0;
  visibility: hidden;
  content: '';
  background: $gray-900;
  border: 2px solid $gray-800;
  border-top-width: 0;
  border-bottom-width: 0;
}
