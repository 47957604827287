@import '~styles/mixins';

.mediaFilterTab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 88px;
  padding-right: 20px;
  padding-left: 112px;
  color: $white;
  border-radius: 24px;
  transition: all 0.25s;

  @include b-d(xxl) {
    justify-content: center;

    > span {
      width: 100%;
      text-align: center;
    }
  }

  @include b-btw(lg, xxl) {
    height: 64px;
    padding: 0 20px 0 84px;
    font-size: 15px;
    line-height: 20px;
  }

  @include b-d(md) {
    height: 52px;
    padding: 0 10px 0 60px;
    font-size: 12px;
    line-height: 16px;
    border-radius: 20px;
  }

  &::before {
    position: absolute;
    z-index: 10;
    display: block;
    content: '';
    background-size: contain;
    transition: all 0.25s;
    transform: translateY(-50%);
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 2px;
    content: '';
    border-radius: 24px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;

    @include b-d(md) {
      border-radius: 20px;
    }
  }

  &:hover {
    color: $gray-300;

    &::before {
      transform: translateY(-50%) scale(1.05);
    }
  }

  &.inactive {
    filter: saturate(0.1);
    opacity: 0.6;
  }

  /* Variant: purple */
  &.purple {
    background: radial-gradient(
      137.41% 108.7% at 93.79% 18.39%,
      rgba(249, 127, 244, 10%) 0%,
      rgba(123, 37, 191, 10%) 24.14%,
      rgba(249, 137, 244, 10%) 41.12%,
      rgba(252, 229, 251, 10%) 57.85%
    );

    &::before {
      top: calc(50% - 4px);
      left: 0;
      width: 112px;
      height: 117px;
      background-image: url('./assets/img-rating.png');

      @include b-btw(lg, xxl) {
        top: calc(50% - 3px);
        left: 2px;
        width: calc(112px / 1.4);
        height: calc(117px / 1.4);
      }

      @include b-d(md) {
        top: calc(50% - 3px);
        left: 2px;
        width: calc(112px / 1.73);
        height: calc(117px / 1.73);
      }
    }

    &::after {
      background: radial-gradient(
        137.41% 108.7% at 93.79% 18.39%,
        #f97ff4 0%,
        #7b25bf 24.14%,
        #f989f4 41.12%,
        #fce5fb 57.85%
      );
    }
  }

  /* Variant: yellow */
  &.yellow {
    background: linear-gradient(
      154.03deg,
      rgba(255, 218, 99, 10%) 0%,
      rgba(251, 255, 67, 10%) 53.44%,
      rgba(243, 220, 5, 10%) 100%
    );

    > span {
      width: min-content;
      text-align: start;
    }

    &::before {
      top: 50%;
      left: 4px;
      width: 107px;
      height: 131px;
      background-image: url('./assets/img-ticket.png');

      @include b-btw(lg, xxl) {
        width: calc(107px / 1.5);
        height: calc(131px / 1.5);
      }

      @include b-d(md) {
        width: calc(107px / 1.84);
        height: calc(131px / 1.84);
      }
    }

    &::after {
      background: linear-gradient(
        154.03deg,
        #ffda63 0%,
        #fbff43 53.44%,
        #f3dc05 100%
      );
    }
  }

  /* Variant: rainbow */
  &.rainbow {
    background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      rgba(241, 150, 209, 10%) 0%,
      rgba(207, 151, 236, 10%) 23.75%,
      rgba(153, 189, 237, 10%) 47.71%,
      rgba(156, 236, 202, 10%) 66.98%,
      rgba(255, 221, 180, 10%) 82.6%,
      rgba(248, 150, 197, 10%) 100%
    );

    &::before {
      top: calc(50% - 2px);
      left: 0;
      width: 110px;
      height: 134px;
      background-image: url('./assets/img-glove.png');

      @include b-btw(lg, xxl) {
        left: 5px;
        width: calc(110px / 1.42);
        height: calc(134px / 1.42);
      }

      @include b-d(md) {
        width: calc(110px / 1.72);
        height: calc(134px / 1.72);
      }
    }

    &::after {
      background: radial-gradient(
        98.54% 76.07% at 93.79% 17.7%,
        #f196d1 0%,
        #cf97ec 23.75%,
        #99bded 47.71%,
        #9cecca 66.98%,
        #ffddb4 82.6%,
        #f896c5 100%
      );
    }
  }

  /* Variant: primary */
  &.primary {
    background: linear-gradient(
      180deg,
      rgba(75, 152, 205, 10%) 0%,
      rgba(31, 207, 177, 10%) 100%
    );

    &::before {
      top: calc(50% - 1px);
      left: 0;
      width: 112px;
      height: 118px;
      background-image: url('./assets/img-blockasset.png');

      @include b-btw(lg, xxl) {
        width: calc(112px / 1.38);
        height: calc(118px / 1.38);
      }

      @include b-d(md) {
        width: calc(112px / 1.66);
        height: calc(118px / 1.66);
      }
    }

    &::after {
      background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    }
  }
}
