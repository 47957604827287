/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/mixins';

@keyframes slide-progress {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

.sliderContainer {
  width: calc(100% + 8px * 4);
  min-width: 0;
  max-width: 100vw;
  margin-left: -16px;

  .dots {
    margin-top: 16px;
    font-size: 0;
    line-height: 0;
    text-align: center;

    button {
      display: inline-block;
      width: 14px;
      height: 8px;
      background-color: $gray-800;
      border-radius: 100px;
    }

    li {
      display: inline-block;
      margin: 0 2px;

      &:global(.slick-active) button {
        position: relative;
        width: 24px;
        overflow: hidden;

        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -20px;
          display: block;
          content: '';
          background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
          border-radius: 100px;
          animation: slide-progress 5s linear forwards;
        }
      }
    }
  }

  :global(.slick-list) {
    margin: 0 8px;
  }

  :global(.slick-slide) {
    padding: 0 8px;
  }

  :global(.slick-arrow) {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 36px;
    height: 36px;
    font-size: 0;
    line-height: 0;
    background-color: rgba(196, 196, 196, 2%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
    border-radius: 100%;
    transform: translateY(-50%);
    backdrop-filter: blur(44px);

    @include b-d(md) {
      width: 32px;
      height: 32px;
      background-size: 14px 14px;
    }

    @supports not (backdrop-filter: blur(8px)) {
      background-color: rgb(0 0 0 / 80%);
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      padding: 2px;
      content: '';
      border-radius: 100%;
      mask: linear-gradient(#fff 0, #fff 100%) content-box,
        linear-gradient(#fff 0, #fff 100%) border-box;
      mask-composite: exclude;
    }

    &:global(.slick-prev) {
      left: -2px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M10 3 5 8l5 5' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

      @include b-d(md) {
        left: 0;
      }

      &::after {
        background: linear-gradient(
          220deg,
          #c4c4c4 -2.92%,
          rgba(196, 196, 196, 0%) 102.92%
        );
      }
    }

    &:global(.slick-next) {
      right: -2px;
      background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='m6 3 5 5-5 5' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

      @include b-d(md) {
        right: 0;
      }

      &::after {
        background: linear-gradient(
          130deg,
          #c4c4c4 -2.92%,
          rgba(196, 196, 196, 0%) 102.92%
        );
      }
    }
  }

  &:hover {
    li {
      &:global(.slick-active) button {
        &::after {
          animation-play-state: paused;
        }
      }
    }
  }
}
