@import '~styles/mixins';

.banner {
  position: relative;
  width: 100%;
  padding-bottom: 25px;
}

.bannerInner {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 40px;
  align-content: start;
  width: 100%;
  height: 188px;
  padding-top: 40px;
  padding-left: 56px;
  overflow: hidden;
  font-weight: 300;
  color: $white;
  text-align: start;
  filter: drop-shadow(0 2px $gray-800) drop-shadow(2px 0 $gray-800)
    drop-shadow(-2px 0 $gray-800) drop-shadow(0 -2px $gray-800);
  border-bottom: 0;
  border-radius: 23px;
  transform: translateZ(0);

  @include b-d(lg) {
    grid-template-columns: auto;
    justify-items: center;
    padding: 12px 0 0;
    text-align: center;
  }
}

.textHeader {
  @include b-d(lg) {
    @include text-joyride-14;
  }
}

.textDescr {
  @include b-d(lg) {
    @include text-joyride-20;
  }
}

.icon {
  @include b-d(lg) {
    width: 49px;
    height: 57px;
  }
}

.blockText {
  display: grid;
  row-gap: 1px;
  margin-top: 20px;
}

.bg {
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
  content: '';
  background: url('./img/banner-orange.png') no-repeat;
  background-color: #121212;
  background-position: center right;
  background-size: 796px;
  mask-image: url("data:image/svg+xml,%3Csvg width='1599' height='191' viewBox='0 0 1599 191' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.75 0.75H1598.25V190.25H1006.5C1002.53 190.25 999.269 186.967 998.354 182.898C994.664 166.5 980.013 154.25 962.5 154.25H634.5C616.987 154.25 602.336 166.5 598.646 182.898C597.731 186.967 594.474 190.25 590.5 190.25H0.75V0.75Z' fill='%23000000'/%3E%3C/svg%3E%0A");
  mask-position: center;
  mask-repeat: no-repeat;
  transform: translateZ(0);

  @include b-d(lg) {
    background-position: right -250px top 0;
  }

  @include b-d(md) {
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' fill='none' viewBox='0 0 600 188'%3E%3Cpath fill='%23000' d='M130 0H0v188h162.5c3.5 0 6.6-3 7.4-6.8a37.5 37.5 0 0 1 36.6-29.2h188a37.5 37.5 0 0 1 36.6 29.2c.8 3.9 3.9 6.8 7.4 6.8H600V0H130Z'/%3E%3C/svg%3E");
    background-position: right -400px top 0;
  }

  &::before {
    position: absolute;
    top: 53px;
    right: 28px;
    bottom: 66px;
    left: 28px;
    z-index: -1;
    content: '';
    background: linear-gradient(
      90deg,
      #212121 2.84%,
      rgba(33, 33, 33, 0%) 90.51%
    );
    filter: blur(57px);
    background-size: cover;
    border-radius: 91px;

    @include b-up(ipad) {
      height: 67px;
    }

    @include b-d(lg) {
      right: 36px;
      left: 36px;
    }

    @include b-d(md) {
      top: 44px;
      right: 7px;
      bottom: 38px;
      left: 7px;
      background: linear-gradient(
        0deg,
        #212121 2.84%,
        rgba(33, 33, 33, 0%) 90.51%
      );
    }
  }
}

.button {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  width: 100%;
  max-width: 374px;
  transform: translateX(-50%);

  @include b-d(md) {
    max-width: 240px;
    height: 48px;
    padding: 0 18px;
    font-size: 12px;
  }
}
