// noinspection CssUnknownTarget
@import '~styles/mixins';

.cardStats {
  position: relative;
  display: grid;
  row-gap: 24px;
  background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      rgb(241 150 209 / 10%) 0%,
      rgb(207 151 236 / 10%) 23.75%,
      rgb(153 189 237 / 10%) 47.71%,
      rgb(156 236 202 / 10%) 66.98%,
      rgb(255 221 180 / 10%) 82.6%,
      rgb(248 150 197 / 10%) 100%
    ),
    $body-bg;
  border-width: 0;

  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: -1;
    content: '';
    background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      #f196d1 0%,
      #cf97ec 23.75%,
      #99bded 47.71%,
      #9cecca 66.98%,
      #ffddb4 82.6%,
      #f896c5 100%
    );
    border-radius: 25px;
  }
}

.statsGrid {
  @include text-syne-16-semi-bold;

  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  font-weight: $font-weight-base;

  > div {
    display: grid;
    row-gap: 8px;
  }

  h5 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: $gray-500;
  }

  b {
    @include text-syne-18-bold;
  }

  @include b-d(md) {
    @include text-syne-14;

    b {
      @include text-syne-14-bold;
    }
  }
}

.btnWrapper {
  display: grid;
}
