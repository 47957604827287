// noinspection CssUnknownTarget
@import '~styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  height: 20px;
}

.track {
  width: 100%;
  height: 4px;
  background-color: $gray-850;
  border-radius: 100px;
}

.thumb {
  position: relative;
  width: 20px;
  height: 20px;
  background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
  border-radius: 100%;

  &::after {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    display: block;
    content: '';
    background: $body-bg
      linear-gradient(
        180deg,
        rgb(75 152 205 / 70%) 0%,
        rgb(31 207 177 / 70%) 100%
      );
    border-radius: 100%;
  }
}
