@import '~styles/mixins';

.buttonCopyWalletAddress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  border: 2px solid $gray-800;
  border-radius: 100px;

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.icon {
  fill: url('#gradSuccess');
}
