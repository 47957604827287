// noinspection CssUnknownTarget
@import '~styles/mixins';

.blockStat {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 52px auto;
  row-gap: 4px;
  column-gap: 16px;
  align-items: center;
  white-space: nowrap;

  @include b-up(lg) {
    text-transform: uppercase;
  }

  @include b-d(lg) {
    column-gap: 12px;
  }

  &.balance {
    svg {
      fill: none;
    }
  }

  .iconWrapper {
    display: flex;
    grid-row: 1 / 3;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: linear-gradient(
      180deg,
      rgb(75 152 205 / 20%) 0%,
      rgb(31 207 177 / 20%) 100%
    );
    border-radius: 100px;

    svg {
      fill: url('#item-gradient-primary');
    }

    &.orange {
      background: linear-gradient(
        180deg,
        rgba(246, 132, 9, 20%) 0%,
        rgba(248, 195, 1, 20%) 100%
      );

      svg {
        fill: url('#item-gradient-orange');
      }
    }
  }

  > h4 {
    align-self: end;
  }

  .textNumber {
    align-self: start;
  }
}

.scalable {
  @include b-d(lg) {
    > h4 {
      @include text-syne-14-bold;
    }

    .textNumber {
      @include text-joyride-15;
    }
  }

  .iconWrapper {
    @include b-d(lg) {
      width: 48px;
      height: 48px;
    }
  }
}
