//noinspection CssUnknownTarget
@import '~styles/mixins.scss';

.btnClose {
  color: $white;
  transition: all 0.25s;

  &:hover {
    color: $gray-300;
  }

  &.isLoading {
    color: $gray-600;
    cursor: wait;
  }
}
