// noinspection CssUnknownTarget
@import '~styles/mixins';

.btnClaim {
  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;

  @include b-btw(md, lg) {
    min-width: 324px;
  }

  @include b-d(md) {
    width: 100%;
    padding: 12px 9px;
  }
}

.iconWrap {
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  background: rgba(247, 160, 6, 20%);
  border-radius: 50%;
}
