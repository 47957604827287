@import '~styles/mixins';

.headerWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-header;
  height: 96px;
  background-color: $black;

  @include b-d(md) {
    height: 80px;
  }
}

.header {
  display: grid;
  grid-auto-flow: column;
  column-gap: 32px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: $black;

  @include b-btw(lg, xxl) {
    column-gap: 8px;
  }

  .navMenu {
    @include b-d(lg) {
      display: none;
    }
  }

  .gridHeaderRight {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    align-items: center;
  }

  .btnWrapper {
    position: relative;
    z-index: $z-index-header + 10;
  }

  .logo {
    width: 276px;
  }

  .btnMenu {
    @include b-up(md) {
      display: none;
    }
  }

  .btnWallet {
    display: flex;
    align-items: center;
  }
}
