@import '~styles/mixins';

.cardNft {
  position: relative;
  display: grid;
  row-gap: 12px;
  padding: 8px;
  color: $white;
  background-color: $gray-900;
  border: 1px solid $gray-800;
  border-radius: 24px;

  &::before {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    content: '';
    background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    border: 1px solid transparent;
    border-radius: 25px;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &.selected {
    &::before,
    .drop {
      opacity: 1;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.imgAspectRatioContainer {
  @include fix-ios-border-radius;

  position: relative;
  align-self: start;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.imgOverlay {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 36%;
  padding: 8px;
  background: linear-gradient(
    180deg,
    rgb(13 13 13 / 90%) 0%,
    rgb(13 13 13 / 0%) 100%
  );
}

.imgOwner {
  @include fix-ios-border-radius;

  overflow: hidden;
  border-radius: 50%;
}

.imgNft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gridAthlete {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.textAthleteName {
  color: transparent;
  background: linear-gradient(180deg, #8d8d8d 0%, #fff 100%);
  background-clip: text;
}

.imgAthlete {
  @include fix-ios-border-radius;

  overflow: hidden;
  border-radius: 100%;
}

.textBuyNow {
  color: $gray-500;
}

.textPrice {
  color: transparent;
  background: $gradient-1-primary;
  background-clip: text;
}

/* stylelint-disable-next-line no-descending-specificity */
.drop {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 5;
  display: block;
  width: 60%;
  max-width: 170px;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 170 29'%3E%3Cpath fill='url(%23a)' d='M28 24.1c2.9 3.1 7 4.9 11.3 4.9h91.4c4.3 0 8.4-1.8 11.4-4.9l9-9.6A98.2 98.2 0 0 1 170 0H0s11.7 6.8 18.9 14.5l9 9.6Z'/%3E%3Cpath stroke='url(%23b)' stroke-width='.8' d='M39.3 28.6h91.4c4.2 0 8.2-1.7 11-4.8 3-3 6.7-7 9.1-9.6A81.2 81.2 0 0 1 168.6.4H1.4a105.3 105.3 0 0 1 26.8 23.4c2.9 3 7 4.8 11.1 4.8Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='85' x2='88.7' y1='29' y2='-27.4' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2326293D' stop-opacity='.1'/%3E%3Cstop offset='1' stop-color='%234054EC' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='85' x2='85' y1='29' y2='0' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236BCFE5'/%3E%3Cstop offset='1' stop-color='%235C6EF1' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(-50%);
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 29'%3E%3Cpath d='M28 24.1c2.9 3.1 7 4.9 11.3 4.9h91.4c4.3 0 8.4-1.8 11.4-4.9l9-9.6A98.2 98.2 0 0 1 170 0H0s11.7 6.8 18.9 14.5l9 9.6Z'/%3E%3C/svg%3E");
  backdrop-filter: blur(10px);

  // Mozilla Firefox
  @supports not (backdrop-filter: none) {
    background-color: rgb(13 13 13 / 60%);
  }

  &::after {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 17.1%;
    content: '';
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: $btn-font-family;
    font-size: 11px;
    color: transparent;
    text-align: center;
    text-transform: uppercase;
    content: 'Added';
    background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    background-clip: text;
    transform: translate(-50%, -50%);
  }
}
