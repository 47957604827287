@import '~styles/mixins';

.mediaPage {
  display: grid;
  row-gap: 48px;
}

.videoDeck {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include b-d(lg) {
    grid-template-columns: auto;
    gap: 8px;
  }
}

.heroSlider {
  @include b-up(lg) {
    margin-top: 16px;
  }
}

.paginator {
  @include b-up(lg) {
    margin-top: -8px;
  }

  @include b-d(lg) {
    margin-top: -24px;
  }
}
