@import '~styles/mixins';

.blockTokenPrice {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 24px 1fr;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  padding: 10px 14px;
  color: $white;
  text-align: start;
  border-radius: 100px;

  &.dropdown {
    grid-template-columns: 24px 1fr 12px;
    background: transparent;

    .iconChevron {
      display: block;
    }
  }

  &.dropdownOpen {
    .iconChevron {
      color: $white;
      transform: rotateX(180deg);
    }
  }

  &.standalone {
    border: 2px solid $gray-800;
  }
}

.logo {
  grid-row: 1 / 3;
}

.textLabel {
  @include font-family-joyride;

  font-size: 8px;
  color: $gray-500;
  letter-spacing: 0.01em;
}

/* stylelint-disable-next-line no-descending-specificity */
.iconChevron {
  display: none;
  grid-row: 1 / 3;
  color: $gray-600;
  transition: all 0.25s;
}

.textPrice {
  color: transparent;
  background: radial-gradient(
    98.54% 76.07% at 93.79% 17.7%,
    #99bded 40%,
    #9cecca 50%,
    #ffddb4 140%
  );

  // noinspection CssInvalidPropertyValue
  background-clip: text;
}
