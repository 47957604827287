@import '~styles/mixins';

.blockBalance {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 24px 1fr;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  color: $white;
  text-align: start;
  border-radius: 100px;
}

.logo {
  grid-row: 1 / 3;
}

.textLabel {
  @include font-family-joyride;

  font-size: 8px;
  color: $gray-500;
  letter-spacing: 0.01em;
}
