@import '~styles/mixins';

.footer {
  position: relative;
  margin-top: 80px;
  overflow: hidden;

  &::after {
    position: absolute;
    right: calc(50% - 720px - 150px);
    bottom: -128px;
    z-index: -1;
    width: 955px;
    height: 284px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 955 284'%3E%3Cg clip-path='url(%23a)' filter='url(%23b)' opacity='.6'%3E%3Cpath stroke='url(%23c)' stroke-width='2' d='M362.263 23.623C496.679-12.393 629.342-3.905 735.157 38.888S919.883 158.747 946.982 259.88c2.86 10.676 5.735 21.231 8.574 31.659 10.668 39.173 20.847 76.552 27.98 111.832 9.03 44.668 13.153 85.865 7.216 122.935-5.931 37.038-21.905 69.981-53.135 98.169-31.252 28.209-77.834 51.702-145.049 69.713-67.276 18.026-114.139 27.013-156.623 29.781-42.477 2.768-80.622-.677-130.478-7.561-16.277-2.248-33.798-4.861-53.118-7.743-99.068-14.778-245.459-36.614-514.197-52.384-35.548-3.752-59.626-15.294-74.176-32.606-14.548-17.311-19.71-40.56-17.068-68.025 5.292-54.99 41.833-126.429 95.833-199.333 53.97-72.866 125.277-147.055 199.9-207.527C217.279 88.304 295.156 41.605 362.263 23.623Z' /%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='c' x1='-161.839' x2='948.116' y1='556.988' y2='259.577' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234B98CD'/%3E%3Cstop offset='1' stop-color='%231FCFB1'/%3E%3C/linearGradient%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h955v284H0z'/%3E%3C/clipPath%3E%3Cfilter id='b' width='1189.29' height='765.892' x='-174.755' y='-11.965' color-interpolation-filters='sRGB' filterUnits='userSpaceOnUse'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend in2='BackgroundImageFix' result='effect1_dropShadow_1116:1789'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_dropShadow_1116:1789' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");

    @include b-btw(md, lg) {
      right: calc(50% - 720px - 48px);
      bottom: -230px;
    }

    @include b-d(md) {
      bottom: -108px;
      left: 38%;
      transform: translateX(-50%);
    }
  }

  &_inner {
    display: grid;
    row-gap: 16px;
    color: $gray-300;

    @include b-btw(md, lg) {
      row-gap: 0;
    }

    @include b-d(md) {
      row-gap: 36px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.footerTop {
  display: grid;
  align-items: start;
  justify-items: start;
  padding: 48px 0;

  @include b-up(xxl) {
    column-gap: 72px;
  }

  @include b-btw(lg, xxl) {
    column-gap: 40px;
  }

  @include b-up(lg) {
    grid-template-columns: 1fr repeat(4, auto);
    grid-auto-flow: column;
  }

  @include b-d(lg) {
    padding: 24px 0;
  }

  @include b-btw(md, lg) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 32px;
  }

  @include b-d(md) {
    grid-template-columns: auto;
    row-gap: 48px;
    justify-items: center;
    text-align: center;
  }

  .topNavLinksList {
    margin: -4px;
  }
}

.blockFooterBox {
  display: grid;
  row-gap: 24px;
  min-width: 130px;
}

.blockLogo {
  @include b-up(lg) {
    justify-items: start;
  }

  @include b-d(lg) {
    justify-items: center;
  }

  @include b-btw(md, lg) {
    grid-column: 1 / 3;
    justify-self: center;
    margin-bottom: 16px;
  }
}

.navHeading {
  color: $white;
  text-transform: uppercase;
}

// stylelint-disable-next-line no-descending-specificity
.topNavLinksList {
  display: grid;
  row-gap: 8px;
  justify-items: start;

  a {
    display: block;
    padding: 4px;
    color: $gray-300;
    text-transform: capitalize;

    &:hover {
      color: $white;
    }
  }

  @include b-d(md) {
    justify-items: center;
    text-align: center;
  }
}

.footerBottom {
  display: grid;
  align-items: center;

  @include b-up(lg) {
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 48px 0;
  }

  @include b-d(lg) {
    row-gap: 24px;
    justify-content: center;
    padding: 24px 0;
    text-align: center;
  }

  @include b-d(md) {
    row-gap: 48px;
  }
}

.bottomLinks {
  display: grid;
  grid-auto-flow: column;
  column-gap: 6px;
  align-items: center;

  @include b-d(lg) {
    grid-row: 1 / 2;
  }

  @include b-d(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include b-d(md) {
    max-width: 240px;
  }

  &_item {
    position: relative;
    white-space: nowrap;

    &:not(:last-child) {
      &::after {
        position: absolute;
        top: 50%;
        right: -3.5px;
        width: 1px;
        height: 16px;
        content: '';
        background: $gray-300;
        transform: translateY(-50%);
      }
    }

    @include b-d(md) {
      &:nth-child(2) {
        &::after {
          display: none;
        }
      }
    }
  }

  &_link {
    display: block;
    padding: 5px;
    color: $gray-300;

    &:hover {
      color: $white;
    }
  }
}
