@import '~styles/mixins';

.headerMobileTop {
  display: grid;
  row-gap: 12px;
  margin-bottom: 8px;

  @include b-up(md) {
    display: none;
  }
}

.hrWalletActions {
  height: 2px;

  @include b-up(md) {
    display: none;
  }
}

.dropdownWalletActions {
  display: grid;
  row-gap: 4px;
  padding: 0;
  margin: -6px -14px;
  list-style-type: none;

  @include b-up(md) {
    display: none;
  }

  button {
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 6px 14px;
    font-weight: inherit;
    color: $white;
    transition: all 0.25s;

    &:hover {
      color: $gray-300;
    }
  }
}
