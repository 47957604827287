@import '~styles/mixins';

.mediaFilterBlock {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;
  scroll-margin-top: 110px;

  @include b-d(lg) {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
  }

  @include b-btw(md, lg) {
    row-gap: 48px;
  }

  @include b-d(md) {
    row-gap: 24px;
    column-gap: 8px;
  }
}
