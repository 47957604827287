@import '~styles/mixins';

.modalConfirmStaking {
  .modalBody {
    position: relative;
    z-index: 1;
    display: grid;
    row-gap: 12px;
  }

  td {
    padding: 0 0 12px;

    &:last-child {
      padding-left: 12px;
      color: $white;
      text-align: end;
    }

    &.textNftCount {
      font-weight: $font-weight-semi-bold;
      color: transparent;
    }
  }
}
