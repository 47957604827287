@import '~styles/mixins';

.cardVideo {
  display: grid;
  grid-template-rows: 1fr repeat(2, auto);
  row-gap: 16px;
  padding: 24px;
  background-color: $gray-900;
  border: 2px solid $gray-800;
  border-radius: 24px;

  @include b-d(md) {
    padding: 16px;
  }

  h5 {
    @include b-d(lg) {
      @include text-syne-16-semi-bold;
    }

    @include b-d(md) {
      font-size: 14px;
    }
  }

  /* Variant: hero */
  &.hero {
    h5 {
      @include b-up(lg) {
        min-height: 90px;
        font-size: 30px;
        line-height: 1.5;
        letter-spacing: -0.01em;
      }

      @include b-btw(md, lg) {
        min-height: 43px;
      }

      @include b-d(md) {
        min-height: 38px;
      }
    }
  }
}

.videoWrapperOuter {
  padding: 2px;
  background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
  border-radius: 26px;
}

.videoWrapperInner {
  @include fix-ios-border-radius;

  position: relative;
  padding-top: calc(9 / 16 * 100%);
  overflow: hidden;
  background-color: $gray-900;
  border-radius: 24px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 2px;
    pointer-events: none;
    content: '';
    user-select: none;
    background: linear-gradient(
      100.22deg,
      rgba(42, 189, 232, 25%) 3.4%,
      rgba(42, 189, 232, 0%) 45.23%,
      rgba(42, 189, 232, 0%) 47.28%,
      rgba(32, 207, 178, 24%) 108.14%
    );
    border-radius: 24px;
    opacity: 0;
    transition: all 0.25s;
  }

  &.glowVisible {
    &::after {
      opacity: 1;
    }
  }
}

.playerRoot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;

  &:hover {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.react-player-play-icon) {
      transform: scale(1.12);
    }
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $gray-600;

  @include b-d(md) {
    @include text-syne-12;

    margin-top: -8px;
  }
}

.ytLink {
  color: $gray-800;

  &:hover {
    color: $gray-700;
  }
}
