.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
  border-radius: 100%;
  transition: transform 0.25s;

  svg {
    width: 54%;
    height: 54%;
    transform: translateX(0.5px);
  }

  &.hero {
    width: 60px;
    height: 60px;
  }
}
