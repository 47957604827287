@import '~styles/mixins';

.modalRewardStore {
  .modalContent {
    overflow: hidden;
  }

  .modalHeader {
    z-index: 1;
    background: rgba(24, 24, 24, 20%);
    backdrop-filter: blur(28px);
    border: none;
    transform: translateZ(0);
  }

  .modalBody {
    padding: 43px 24px 44px;
    border-top: 1px solid rgba($white, 10%);
  }
}

.description {
  font-size: 29px;
  line-height: 32px;
  text-transform: uppercase;

  @include b-d(md) {
    font-size: 20px;
  }
}

.iconWrap {
  position: absolute;
  top: -22px;
  right: -33px;
  transform: matrix(1, 0.07, -0.1, 1, 0, 0);

  @include b-d(ipad) {
    top: -20px;
  }

  @include b-d(md) {
    top: -17px;
    right: -47px;
  }

  svg {
    @include b-d(md) {
      width: 119px;
      height: 138px;
    }
  }
}
